import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommentCount, DiscussionEmbed } from 'disqus-react';
import contentParser from 'gatsby-wpgraphql-inline-images';
import hljs from 'highlight.js';
import csharp from 'highlight.js/lib/languages/csharp';
import 'highlight.js/styles/github-gist.css';
import React, { FunctionComponent, useEffect } from 'react';
import { WpGraphQl_Post } from '../../../graphql-types';
import Config from '../../config';
import { getPostUri } from '../../util/PostUtil';
import PostInfo from '../post-info/post-info.component';
import styles from './post.component.module.scss';

type PostProps = {
  post: WpGraphQl_Post,
  url: string,
  showHeader: boolean,
  showComments: boolean
};

const Post: FunctionComponent<PostProps> = ({ post, url, showHeader, showComments }) => {
  const getHeader = () => showHeader ? <a href={url} className={styles.title}><h1>{post.title}</h1></a> : '';

  useEffect(() => {
    hljs.registerLanguage('csharp', csharp);
    hljs.initHighlightingOnLoad();

    document.querySelectorAll('pre code').forEach((block: Element) => {
      hljs.highlightBlock(block.parentElement as HTMLElement);
    });
  });

  return (
    <div className={styles.post}>
      {getHeader()}
      {/* @ts-ignore */}
      <PostInfo categories={post.categories?.edges?.map(e => e!.node)} post={post} className={styles.postInfo}
                url={url}/>
      {/* @ts-ignore */}
      <main className={`${styles.content}`}>
        {contentParser({ content: post.content }, {
          wordPressUrl: Config.wordpress.baseUrl,
          uploadsUrl: Config.wordpress.uploadsUrl
        })}
      </main>
      {showComments ? (
        <a id={'comments'}>
          <DiscussionEmbed shortname={Config.disqus.shortname}
                           config={Config.disqus.getConfig(url, post.id, post.title!)}/>
        </a>
      ) : (
        <>
          <a href={`${getPostUri(post)}#comments`} className={styles.commentButton}>
            <FontAwesomeIcon icon={faComment}/>
            <CommentCount shortname={Config.disqus.shortname}
                          config={Config.disqus.getConfig(url, post.id, post.title!)}>0 comments</CommentCount>
          </a>
        </>
      )}
    </div>
  );
};

export default Post;