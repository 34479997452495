import seedrandom from 'seedrandom';

const colors = [
  'f94144',
  'f3722c',
  'f8961e',
  'f9844a',
  'f9c74f',
  '90be6d',
  '43aa8b',
  '4d908e',
  '577590',
  '277da1',
];

const CategoryUtil = {
  getColor(categoryId: string) {
    const max = Object.keys(colors).length;
    const rnd = seedrandom(categoryId)() * max;
    const index = Math.floor(rnd);

    return `#${colors[index]}`;
  }
};

export default CategoryUtil;