import { CommentCount } from 'disqus-react';
import React, { FunctionComponent } from 'react';
import Moment from 'react-moment';
import { Maybe, WpGraphQl_Category, WpGraphQl_Post } from '../../../graphql-types';
import Config from '../../config';
import CategoryUtil from '../../util/CategoryUtil';
import FadeInSection from '../fade-in-section/fade-in-section.component';
import styles from './post-info.component.module.scss';

type PostInfoProps = {
  post: WpGraphQl_Post
  url: string,
  className?: string,
  categories: Maybe<WpGraphQl_Category>[]
};

const PostInfo: FunctionComponent<PostInfoProps> = ({ post, url, className, categories }) => {
  const fadeSpeed = 3;

  const avatarStyle = {
    backgroundImage: `url('${post.author?.node?.avatar?.url}')`
  };
  const getCategoriesHtml = () =>
    categories.map((c, i) => {
      const categoryStyle = {
        color: CategoryUtil.getColor(c!.id)
      };

      return (
        <span key={c!.name}>
          <a className={styles.category} href={c!.uri} style={categoryStyle}>{c!.name}</a>
          {i < categories.length - 1 ? ', ' : ''}
        </span>
      );
    });

  const getCommentCount = () => {
    return <a href={`${url}#comments`} className={styles.comments}>
      <CommentCount shortname={Config.disqus.shortname} config={Config.disqus.getConfig(url, post.id, post.title!)}>0
        comments</CommentCount>
    </a>;
  };

  const separator = (<span>&nbsp;·&nbsp;</span>);

  const categoriesTemplate = categories ? (
    <span>
      {separator}{getCategoriesHtml()}
    </span>
  ) : '';

  return (
    <FadeInSection speed={fadeSpeed} className={`${styles.authorInfo} ${className}`}>
      <div className={styles.avatar} style={avatarStyle}/>
      <div className={styles.additionalInfo}>
        <span>By {post.author?.node?.firstName}{separator}{getCommentCount()}</span>
        <span><Moment format={'LL'}>{post.date!}</Moment>{categoriesTemplate}</span>
      </div>
    </FadeInSection>
  );
};

export default PostInfo;
