import React, { FunctionComponent } from 'react';
import { Maybe, WpGraphQl_Category } from '../../../graphql-types';
import FadeInSection from '../fade-in-section/fade-in-section.component';
import styles from './categories-list.component.module.scss';

type CategoriesListProps = {
  categories: Maybe<WpGraphQl_Category>[]
};

const CategoriesList: FunctionComponent<CategoriesListProps> = ({ categories }) => {
  return (
    <FadeInSection speed={3}>
      <ul className={styles.categoriesList}>
        {categories.map(c => (
          <li key={c!.name}>
            <a href={c!.uri}>{c!.name}</a>
          </li>
        ))}
      </ul>
    </FadeInSection>
  );
};

export default CategoriesList;
