import React, { FunctionComponent } from 'react';
import FadeInSection from '../fade-in-section/fade-in-section.component';
import TreeViewNode, { TreeViewNodeConfig } from './tree-view-node.component';

type TreeViewProps = {
  rootNodes: TreeViewNodeConfig[];
}

const TreeView: FunctionComponent<TreeViewProps> = ({ rootNodes }) => {
  return (
    <FadeInSection speed={3}>
        {rootNodes.map(node => (
          <TreeViewNode key={node.title} node={node} isRootNode={true}/>
        ))}
    </FadeInSection>
  );
};

export default TreeView;
