import { graphql } from 'gatsby';
import { PageContext } from 'gatsby/internal';
import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { WpGraphQl_Category, WpGraphQl_Post } from '../../../graphql-types';
import Archive from '../../components/archive/archive.component';
import CategoriesList from '../../components/categories-list/categories-list.component';
import Contact from '../../components/contact/contact.component';
import Logo from '../../components/header/logo/logo.component';
import Navbar from '../../components/header/navbar/navbar.component';
import Post from '../../components/posts/post.component';
import Config from '../../config';
import globalStyles from '../../styles/global.module.scss';
import styles from './post.page.module.scss';

type PostPageProps = {
  pageContext: PageContext,
  data: any
}

const PostPage: FunctionComponent<PostPageProps> = ({ pageContext, data }) => {
  // @ts-ignore
  const categories: WpGraphQl_Category[] = data.wpcontent.categories!.nodes;
  // @ts-ignore
  const allPosts: WpGraphQl_Post[] = data.wpcontent.allPosts.nodes;
  // @ts-ignore
  const postsByContext: WpGraphQl_Post[] = data.wpcontent.postsByCategory.nodes;

  const backgroundStyle = {
    backgroundImage: `url('${pageContext.isCollection ? Config.images.archive : postsByContext[0].featuredImage?.node?.imageFile?.childImageSharp?.fixed?.src}')`
  };

  // @ts-ignore
  const getTitle = () => pageContext.isCollection ? `About ${pageContext.title.toLowerCase()}...` : postsByContext[0].title;
  const gridElements = postsByContext.map((post, i) => {
    const url = `${Config.siteUrl}${pageContext.url}/${post.slug}`;
    const showComments = !pageContext.isCollection || postsByContext.length === 1;
    return <Post key={post.title} post={post} url={url} showHeader={pageContext.isCollection}
                 showComments={showComments}/>;
  });

  gridElements.splice(1, 0, (
    <aside key={'sidebar'} className={styles.sidebar}>
      <h3>Monthly Archive</h3>
      <Archive posts={allPosts}/>
      <h3>What about?</h3>
      <CategoriesList categories={categories}/>
    </aside>
  ));

  return (
    <>
      <Helmet>
        <title>{Config.siteTitle} - {getTitle()}</title>
      </Helmet>
      <Navbar/>
      <Logo startSmall={true}/>
      <div className={styles.backgroundWrapper}>
        <div className={styles.background} style={backgroundStyle}/>
      </div>
      <div className={`${globalStyles.contentContainer} ${styles.title}`}>
        <div className={globalStyles.content}>
          <div className={globalStyles.container10}>
            {/* @ts-ignore */}
            <h1 className={styles.title}>{getTitle()}</h1>
          </div>
        </div>
      </div>
      <div className={`${globalStyles.contentContainer} ${globalStyles.main}`}>
        <div className={`${globalStyles.content} ${styles.postContainer}`}>
          {gridElements}
        </div>
      </div>
      <div className={`${globalStyles.contentContainer} ${globalStyles.diagonalBackground}`}>
        <Contact/>
      </div>
    </>
  );
};

export default PostPage;

export const query = graphql`
query postsByCategory($ids: [ID!]){
  wpcontent {
    postsByCategory: posts(where: {in: $ids}) {
      nodes {
        uri
        title     
        slug 
        content
        isPreview
        date
        featuredImage {
          node {
            sourceUrl
            imageFile {
              childImageSharp {
                fixed {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
        }
        author {
          node {
            firstName
            avatar {
              url
            }
          }
        }
        categories {
          edges {
            node {
              id
              name
              uri
            }
          }
        }
      }
    }
    categories {
      nodes {
        uri
        name
      }      
    }
    allPosts: posts {
      nodes {
        title
        date
        uri
      }
    }
  }
}`;