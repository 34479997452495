import _ from 'lodash';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { WpGraphQl_Post } from '../../../graphql-types';
import FadeInSection from '../fade-in-section/fade-in-section.component';
import { TreeViewNodeConfig } from '../tree-view/tree-view-node.component';
import TreeView from '../tree-view/tree-view.component';

type ArchiveProps = {
  posts: WpGraphQl_Post[];
};

type YearMonthGroupedDictionary = { [year: string]: _.Dictionary<WpGraphQl_Post[]>; };

function getPostDate(post: WpGraphQl_Post, format: 'year' | 'month'): moment.Moment {
  return moment(post.date).startOf(format);
}

const Archive: FunctionComponent<ArchiveProps> = ({ posts }) => {
  let postsByYear = _.groupBy(posts, post => getPostDate(post, 'year').year().toString());
  const years: YearMonthGroupedDictionary = {};

  _.keys(postsByYear).forEach((year: string) => {
    years[year] = _.groupBy(postsByYear[year], post => {
      const month = (getPostDate(post, 'month').month() + 1);
      return moment(month, 'MM').format('MM');
    });
  });

  const data: TreeViewNodeConfig[] = [];

  Object.keys(years).forEach(year => {
    const monthNodes = Object.keys(years[year]).map(month => {
      const posts = years[year][month];

      return {
        title: moment(month, 'MM').format('MMMM'),
        isToggled: false,
        uri: `/${year}/${month}`
        /*children: posts.map(post => ({
          title: post.title!,
          isToggled: false,
          uri: `/${year}/${month}/${post.slug}`
        }))*/
      };
    });

    data.push({
      title: year,
      isToggled: false,
      children: monthNodes
    });
  });

  return (
    <FadeInSection speed={3}>
      <TreeView rootNodes={data}/>
    </FadeInSection>
  );
};

export default Archive;
